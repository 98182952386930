<template>
    <div v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <Header/>
        <div class="content_texts">
      <!-- <router-link :to="{path:'../'}">
        <img class="content_imgs_1" src="../../assets/content/logo_s.png">
      </router-link> -->
        <img class="content_imgs_2" src="../../assets/content/logo_yi.png">
        <div calss="content_studys">{{my_favorite}}</div>
    </div>
    
    <div class="lesson-item_s" v-for="items in lesson_item_list" :key="items" @click="lessonItem(items.id)">
          <div class="lesson-item_1_s">
            <img :src="items.url">
          </div>
          <div class="lesson-item_2_s">
              <p class="pl_1_s">{{items.title}}</p>
              <p class="pl_2_s">{{items.sub_title}}</p>
              <p class="pl_3_s">{{items.third_title}}</p>
          </div>
        </div>
    </div>
</template>

<script>
import Header from "../Header";
import {apiUserCourseFavoritesList,apiCoursesDetail} from "@/request/api";
export default {
  components:{Header},
  data(){
    return {
      loading:true,
      lesson_item_list:[],
      my_favorite:'',
    }
  },
  created(){
    let lang_id = this.$store.state.lang_id;
    if(lang_id == 1){
        this.my_favorite = this.$zh_ft('my_favorite');
    }else{
        this.my_favorite = this.$zh_jt('my_favorite');
    }

    apiUserCourseFavoritesList({
      lang_id:this.$store.state.lang_id
    }).then(res=>{
      if(res.code == 200){
        let length = res.data.data.length;
        if(length > 0){
          for(let i= 0; i<length; i++){
            apiCoursesDetail(res.data.data[i].course_id,{
              lang_id:this.$store.state.lang_id,
              user_id:res.data.data[i].user_id
            }).then(ress=>{
              if(ress.code == 200){
                this.lesson_item_list.push({
                  id:ress.data.id,
                  url:ress.data.cover,
                  title:ress.data.title,
                  sub_title:ress.data.sub_title,
                  third_title:ress.data.third_title
                });
              }
              this.loading = false;
            }).catch(error=>{
              this.loading = false;
              console.log(error);
            })
          }
        }else{
          this.loading = false;
        }
      }
    }).catch(error=>{
      console.log(error);
    })
  },
  methods:{
    lessonItem(id = 0){
      if(id){
        this.$router.push({
          path:'/training_schedule/'+id
        });
      }
    }
  }
}
</script>
<style>
/* //修改loading加载时的颜色 */
    .el-loading-spinner .path{
      stroke: rgb(203, 167, 126);
    }
.pl_1_s{
  font-family: Helvetica Neue;
    font-weight: 300;
    font-size: 12px;
    text-align: left;
    color: rgba(0,0,0,.7);
}
.pl_2_s{
  font-family: Helvetica Neue;
    font-weight: 500;
    font-size: 20px;
    text-align: left;
    color: rgba(0,0,0,.7);
}
.pl_3_s{
    font-family: Helvetica Neue;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    color: rgba(0,0,0,.7);
}
.lesson-item_2_s{
  width: 65%;
  height: auto;
  margin: auto 5px auto 5px;
}
.lesson-item_1_s{
  width: 35%;
  height: auto;
}
.lesson-item_1_s img{
  width: 100%;
  height:100%;
}
.lesson-item_s{
  height: auto;
  border-bottom: 1px solid #fff;
  display: flex;
  position: relative;
  min-height: 70px;
}
.lesson-item_2_s p{
  margin: auto;
  word-wrap: break-word;
}
.content_studys{
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
 .content_imgs_1{
    margin-right: 13px;
    width: 60px;
    height: 55px;
  }
  .content_imgs_2{
    width: 50px;
    height: 42px;
    margin-right: 8px;
  }
.content_texts{
    padding-left: 17px;
    height: 76px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
</style>